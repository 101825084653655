<template>
  <div>
    <q-form ref="requestEditForm">
      <c-card title="작업장비 대여 신청" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline>
            <c-btn 
              v-show="editable && param.isOld && !disabled" 
              :url="completeUrl"
              :isSubmit="isComplete"
              :param="param.equipmentRent"
              mappingType="PUT"
              label="대여 신청" 
              icon="check"
              @beforeAction="completeData"
              @btnCallback="completeCallback" />
            <c-btn 
              v-show="editable && !disabled" 
              label="LBLSAVE" 
              :url="saveUrl"
              :isSubmit="isSave"
              :param="param.equipmentRent"
              :mappingType="saveType"
              icon="save" 
              @beforeAction="saveRequest"
              @btnCallback="saveCallback" />
            <c-btn 
              v-show="editable && param.isOld && !disabled" 
              label="LBLREMOVE" 
              icon="delete_forever" 
              @btnClicked="remove" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant
              :required="true"
              :disabled="disabled"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="param.equipmentRent.plantCd"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :disabled="disabled"
              :editable="editable"
              default="today"
              name="requestDate"
              label="신청일"
              v-model="param.equipmentRent.requestDate"
            ></c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-text
              :disabled="true"
              :editable="editable"
              type="dept_user"
              label='신청자'
              name="regUserId"
              v-model="request"
            />
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="tableRequest"
      class="q-mt-sm"
      title="장비 대여신청 목록"
      :columns="gridRequest.columns"
      :data="param.equipmentRent.requestItems"
      :gridHeight="gridHeight"
      :editable="editable && !disabled"
      :isFullScreen="false"
      :columnSetting="false"
      :filtering="false"
      :usePaging="false"
      :isExcelDown="false"
      rowKey="sopEquipmentRequestItemId"
      selection="multiple"
    >
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn v-if="editable && !disabled" label="LBLADD" icon="add" @btnClicked="addEquips" />
          <c-btn v-if="editable && !disabled" label="LBLEXCEPT" icon="remove" @btnClicked="removeRequest" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'closedSpaceEquipApply',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopEquipmentRentId: '',
        equipmentRent: {
          sopEquipmentRentId: '',  // 작업장비 대여 일련번호
          plantCd: '',  // 사업장 코드
          equipRentStatusCd: '',  // 진행단계
          requestDate: '',  // 신청일
          sopWorkPermitId: null,  // 작업허가서 일련번호
          permitNo: '',
          regUserId: '',  // 등록자 ID
          regUserName: '',  // 등록자 ID
          regUserDeptName: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          requestItems: [],
          deleteRequestItems: [],
          rentReturnItems: [],
        },
        isOld: false,
        stepUid: '',
      }),
    },
    height: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      gridRequest: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '장비종류',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '장비명',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            required: true,
            name: 'requestCount',
            field: 'requestCount',
            label: '신청수량',
            style: 'width:90px',
            align: 'right',
            type: 'number',
            sortable: false,
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: '단위',
            style: 'width:60px',
            align: 'center',
            sortable: false,
          },
          {
            required: true,
            name: 'requestRentPeriod',
            field: 'requestRentPeriod',
            label: '대여신청기간',
            style: 'width:150px',
            align: 'center',
            range: true,
            type: 'date',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            type: 'text',
            style: 'width:200px',
            sortable: false,
          },
        ],
        data: [],
        height: '600px'
      },
      editable: true,
      saveType: 'POST',
      isSave: false,
      isComplete: false,
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    disabled() {
      return this.param.equipmentRent.equipRentStatusCd !== 'CSE0000001' 
        && this.param.isOld
    },
    gridHeight() {
      let _height = this.height;
      if (_height) {
        _height = String(Number(this.$_.replace(_height, 'px', '')) - 150) + 'px'
      } else {
        _height = '600px'
      }
      return _height;
    },
    request() {
      return this.param.equipmentRent.regUserDeptName ? `${this.param.equipmentRent.regUserDeptName} / ${this.param.equipmentRent.regUserName}` : `${this.param.equipmentRent.regUserName}`
    }
  },
  watch: {
    'param.stepUid'() {
      this.$refs["tableRequest"].reRend();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.hea.cse.gives.insert.url;
      this.deleteUrl = transactionConfig.hea.cse.gives.delete.url;
      this.completeUrl = transactionConfig.hea.cse.gives.complete.url;
      // code setting
      // list setting
    },
    /* eslint-disable no-unused-vars */
    removeRequest() {
      let selectData = this.$refs["tableRequest"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.param.equipmentRent.deleteRequestItems) {
            this.param.equipmentRent.deleteRequestItems = [];
          }
          if (this.$_.findIndex(this.param.equipmentRent.deleteRequestItems, {
            sopEquipmentRequestItemId: item.sopEquipmentRequestItemId,
          }) === -1 && item.editFlag !== "C") {
            this.param.equipmentRent.deleteRequestItems.push(item);
          }
          this.param.equipmentRent.requestItems = this.$_.reject(this.param.equipmentRent.requestItems, item);
        });
      }
    },
    addEquips() {
      this.popupOptions.title = '장비목록 팝업';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/hea/closedSpaceEquipStockPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeStockPopup;
    },
    closeStockPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.param.equipmentRent.requestItems) {
          this.param.equipmentRent.requestItems = [];
        }
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.param.equipmentRent.requestItems, { heaClosedSpaceEquipmentId: item.heaClosedSpaceEquipmentId }) === -1) {
            this.param.equipmentRent.requestItems.push({
              sopEquipmentRentId: this.param.sopEquipmentRentId,  // 작업장비 대여 일련번호
              sopEquipmentRequestItemId: uid(),  // 장비 신청 목록 일련번호
              heaClosedSpaceEquipmentId: item.heaClosedSpaceEquipmentId,  // 신청 장비 일련번호
              equipmentTypeName: item.equipmentTypeName,  // 신청 장비 일련번호
              equipmentName: item.equipmentName,  // 신청 장비 일련번호
              unitName: item.unitName,  // 신청 장비 일련번호
              requestCount: '',  // 신청 장비 갯수
              requestRentStartDate: '',  // 대여 신청 시작 일자
              requestRentEndDate: '',  // 대여 신청 종료 일자
              remark: '',  // 비고
              regUserId: this.$store.getters.user.userId, 
              editFlag: 'C',
            })
          }
        })
      }
    },
    saveRequest() {
      if (this.param.isOld) {
        this.saveUrl = transactionConfig.hea.cse.gives.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.hea.cse.gives.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['requestEditForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.gridRequest.columns, this.param.equipmentRent.requestItems, '장비 대여신청')) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.param.equipmentRent.regUserId = this.$store.getters.user.userId
                this.param.equipmentRent.chgUserId = this.$store.getters.user.userId   
                
                this.$_.forEach(this.param.equipmentRent.requestItems, _item => {
                  if (_item.requestRentPeriod && _item.requestRentPeriod.length > 0) {
                    _item.requestRentStartDate = _item.requestRentPeriod[0]
                    _item.requestRentEndDate = _item.requestRentPeriod[1]
                  }
                })
                
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.param.sopEquipmentRentId) {
        this.$set(this.param, 'sopEquipmentRentId', result.data)
        this.$emit('emitStep', {
          name: 'setId',
          param: this.param.sopEquipmentRentId,
        })
      } else {
        this.$emit('emitStep', {
          name: 'getDetail',
        })
      }
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: 'MSGREMOVE',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.sopEquipmentRentId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeData() {
      this.$refs['requestEditForm'].validate().then(_result => {
        if (this.$comm.validTable(this.gridRequest.columns, this.param.equipmentRent.requestItems, '장비 대여신청')) {
          if (_result) { 
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '대여 신청하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.param.equipmentRent.chgUserId = this.$store.getters.user.userId   

                this.$_.forEach(this.param.equipmentRent.requestItems, _item => {
                  if (_item.requestRentPeriod && _item.requestRentPeriod.length > 0) {
                    _item.rentStartDate = _item.requestRentPeriod[0]
                    _item.rentEndDate = _item.requestRentPeriod[1]
                  }
                })

                this.isComplete = !this.isComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('emitStep', {
        name: 'getDetail',
      })
    },
  },
};
</script>
